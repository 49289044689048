import React from "react";
import instagram from "../../../assets/medias/instagram.png";
import facebook from "../../../assets/medias/facebook.png";
import styles from "./style.module.css";
import CONSTANTS from "../../../constants";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.contacts}>
        <div>
          <div className={styles.talkWithUs}>Fale conosco</div>
          Whatsapp: {CONSTANTS.telefone}
          <br />
          <br />
          Email: {CONSTANTS.email}
          <br />
          <iframe
            title="Google Maps Address"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.7816315313576!2d-49.20991408458624!3d-25.44556313973999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce5612e471ceb%3A0x15d7d6d847bcc735!2sRua%20Em%C3%ADlio%20Bertolini%2C%20920%20-%20Cajuru%2C%20Curitiba%20-%20PR%2C%2082920-030!5e0!3m2!1spt-BR!2sbr!4v1662502199543!5m2!1spt-BR!2sbr"
            loading="lazy"
            className={styles.googleMaps}
          />
        </div>
        <div>
          <div className={styles.talkWithUs}>Midias Sociais</div>
          <div className={styles.socialMedias}>
            Instagram:{" "}
            <a href={CONSTANTS.instagram} target="_blank" rel="noreferrer">
              <img src={instagram} width="23px" alt="t" />
            </a>
          </div>
          <div className={styles.socialMedias}>
            Facebook:{" "}
            <a href={CONSTANTS.facebook} target="_blank" rel="noreferrer">
              <img src={facebook} width="23px" alt="t" />
            </a>
          </div>
        </div>
      </div>
      <hr className={styles.footerDivider} />
      <div className={styles.disclaimer}></div>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import kitchenIcon from "../../assets/icons/kitchen.png";
import bedroomIcon from "../../assets/icons/bedroom.png";
import sofaIcon from "../../assets/icons/sofa.png";
import closetIcon from "../../assets/icons/closet.png";
import { Box } from "@mui/system";
import CONSTANTS from "../../constants";
import styles from "./style.module.css";
import IMAGES from "../../assets/presentation/images";

const HomePage = () => {
  const firstSlideSection = [
    IMAGES.sample_2,
    IMAGES.sample_4,
    IMAGES.sample_5,
    IMAGES.sample_6,
    IMAGES.sample_7,
  ];

  const secondSlideSection = [
    { images: [IMAGES.living_room_1, IMAGES.living_room_2] },
    { images: [IMAGES.bedroom_1, IMAGES.closet_1] },
  ];

  const thirdSlideSection = [
    { images: [IMAGES.bedroom_2, IMAGES.bedroom_3] },
    { images: [IMAGES.sample_1, IMAGES.sample_3] },
    { images: [IMAGES.bedroom_4, IMAGES.studio_1] },
  ];

  const fourthSlideSection = [
    { images: [IMAGES.kitchen_1, IMAGES.kitchen_2] },
    { images: [IMAGES.kitchen_3, IMAGES.kitchen_4] },
    { images: [IMAGES.kitchen_5, IMAGES.kitchen_6] },
  ];

  const environments = [
    {
      icon: kitchenIcon,
      descripition:
        "Uma cozinha só se torna prática e funcional quando organizada com eletrodomésticos e móveis bem distribuídos.\
         Armários, bancadas e prateleiras são soluções práticas para organizar panelas e utensílios.",
    },
    {
      icon: bedroomIcon,
      descripition:
        "Ter um quarto aconchegante e bem organizado pode fazer toda a diferença no seu bem-estar,\
        conforto e na qualidade de vida em casa.",
    },
    {
      icon: sofaIcon,
      descripition:
        "A escolha adequada dos móveis é crucial para ter uma sala aconchegante, afinal, eles precisam ser confortáveis.\
         O ideal é que estes móveis unam design, funcionalidade e conforto. .",
    },
    {
      icon: closetIcon,
      descripition:
        "Um closet planejado auxilia na hora de organizar o quarto e dá muita funcionalidade ao ambiente.\
        Dessa maneira, quando o closet é feito sob medida, ele se adapta ao espaço disponível",
    },
  ];

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className={styles.container}>
      <Carousel
        duration={2000}
        interval={5000}
        animation="fade"
        stopAutoPlayOnHover={false}
        className={styles.firstCarouselSection}
      >
        {firstSlideSection.map((item, i) => (
          <div key={i} className={styles.slide}>
            <img src={item} alt="t" />
          </div>
        ))}
      </Carousel>
      <div className={styles.smallPresentation}>
        <h2>Garcez Móveis Planejados</h2>
        <br />
        <div>Tornando sonhos realidade</div>
      </div>
      <div id="quem-somos">
        <Carousel
          indicators={false}
          duration={2000}
          interval={5000}
          animation="slide"
          className={styles.secondCarouselSection}
        >
          {secondSlideSection.map((item, i) => (
            <div key={i} className={styles.secondSlideSection}>
              {item.images.map((image) => (
                <img key={image} src={image} alt="t" />
              ))}
            </div>
          ))}
        </Carousel>
        <div className={styles.businessValues}>
          <div className={styles.businessValuesDescription}>
            <h2>Design e Inovação</h2>
            <br />A Garcez Personalizados alia know-how ao acompanhamento das
            tendências de mercado, desenvolvendo projetos personalizados e
            adaptados aos novos estilos de vida. O objetivo é que o ambiente em
            foco se misture com as outras áreas da casa, e nada melhor do que um
            design bem pensado para atingir essa meta. Ambientes adaptados aos
            estilos de vida individuais, com foco no melhor aproveitamento do
            espaço, qualidade, resistência e funcionalidade dos móveis.
          </div>
          <a href="#quotation" rel="noreferrer">
            <Button className={styles.quotationButton}>
              Solicite seu orçamento
            </Button>
          </a>
        </div>
      </div>
      <div id="missao-valor-visao">
        <Carousel
          indicators={false}
          duration={2000}
          interval={5000}
          animation="slide"
          className={styles.secondCarouselSection}
        >
          {thirdSlideSection.map((item, i) => (
            <div key={i} className={styles.secondSlideSection}>
              {item.images.map((image) => (
                <img key={image} src={image} alt="t" />
              ))}
            </div>
          ))}
        </Carousel>
        <div className={styles.businessValues}>
          <div className={styles.businessValuesDescription}>
            <h2>Qualidade, Confiança e Garantia</h2>
            <br />
            Trabalhamos com os melhores fornecedores do mercado em
            matérias-primas, ferragens e acessórios, para garantir um produto de
            qualidade, e visando a sustentabilidade. Todas as matérias-primas
            são de extrema qualidade, e todos os produtos são testados à
            exaustão, para aferir e garantir a máxima resistência perante as
            temperaturas, peso ou utilização constante. Cozinhas modernas
            personalizadas Cozinhas modernas, sob medida, adaptadas ao espaço e
            integradas no ambiente envolvente. Projetos de cozinha em que
            organização, funcionalidade e bem-estar, são a essência da cozinha
            Garcez Personalizados.
          </div>
          <a href="#quotation" rel="noreferrer">
            <Button className={styles.quotationButton}>
              Solicite seu orçamento
            </Button>
          </a>
        </div>
      </div>
      <div id="kitchen">
        <Carousel
          indicators={false}
          duration={2000}
          interval={5000}
          animation="slide"
          className={styles.secondCarouselSection}
        >
          {fourthSlideSection.map((item, i) => (
            <div key={i} className={styles.secondSlideSection}>
              {item.images.map((image) => (
                <img key={image} src={image} alt="t" />
              ))}
            </div>
          ))}
        </Carousel>
        <div className={styles.businessValues}>
          <div className={styles.businessValuesDescription}>
            <h2>Cada cozinha é única</h2>
            <br />
            Para a Garcez Personalizados, cada cozinha é única e nada é deixado
            ao acaso. As cozinhas são ambientes para conviver em família e
            receber os amigos, e independentemente do tamanho, é possível criar
            ambientes personalizados e de sonho. A cozinha ideal é aquela em que
            nos sentimos bem, que nos traz doces memórias do passado e na qual
            nos vemos a cozinhar no futuro, uma cozinha nossa.
          </div>
        </div>
      </div>
      <div id="ambientes">
        <div className={styles.businessValues}>
          <h2>Diversos Ambientes</h2>
          <div className={styles.environments}>
            {environments.map((item) => (
              <div className={styles.environmentsItem}>
                <img src={item.icon} key={item.icon} alt="t" />
                {item.descripition}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Box
        id="quotation"
        component="form"
        autoComplete="off"
        className={styles.quotationForm}
      >
        <h2>Orçamento</h2>
        <div className={styles.quotationLines}>
          <TextField
            InputProps={{ className: styles.quotationFields }}
            required
            id="outlined-required"
            label="Nome Completo"
            onChange={handleNameChange}
          />
          <TextField
            InputProps={{ className: styles.quotationFields }}
            required
            id="outlined-required"
            label="Telefone com DDD"
            type="tel"
            onChange={handlePhoneChange}
          />
          <TextField
            InputProps={{ className: styles.quotationFields }}
            required
            id="outlined-required"
            label="Email"
            onChange={handleEmailChange}
          />
        </div>
        <a
          href={`${CONSTANTS.whatsapp}?text=${encodeURIComponent(
            `Olá, me chamo ${name} e gostaria de solicitar um orçamento. Meu telefone é o ${phone} e meu e-mail é o ${email}.`
          )}`}
          rel="noreferrer"
          target="_blank"
        >
          <Button className={styles.quotationButton}>Entrar em contato</Button>
        </a>
      </Box>
    </div>
  );
};

export default HomePage;

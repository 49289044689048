import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useScrollTrigger,
} from "@mui/material";
import React, { useState } from "react";
import logo_dark from "../../../assets/medias/logo-dark.png";
import whatsapp from "../../../assets/medias/whatsapp.png";
import instagram from "../../../assets/medias/instagram.png";
import facebook from "../../../assets/medias/facebook.png";
import { Menu } from "@mui/icons-material";
import styles from "./style.module.css";
import CONSTANTS from "../../../constants";

const Header = () => {
  interface Props {
    window?: () => Window;
    children: React.ReactElement;
  }

  const headerItems = [
    { name: "Quem somos", link: "#quem-somos" },
    { name: "Ambientes", link: "#ambientes" },
    { name: "Conteúdos", link: "#missao-valor-visao" },
    { name: "Contato", link: "#quotation" },
  ];

  const OpacityOnScroll = (props: Props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      target: window ? window() : undefined,
      threshold: 0,
    });

    return (
      <div
        className={`${styles.header} ${
          trigger ? styles.transparentHeader : ""
        }`}
      >
        {children}
      </div>
    );
  };

  const DesktopItems = () => {
    return (
      <>
        {headerItems.map((item) => (
          <div
            key={item.name}
            className={`${styles.rightSideItems} ${styles.desktopMenuItem}`}
          >
            <a key={item.name} href={item.link}>
              {item.name}
            </a>
          </div>
        ))}
      </>
    );
  };

  const SocialItems = () => {
    return (
      <div className={styles.rightSideItems}>
        <div className={styles.social}>
          <a href={CONSTANTS.whatsapp} target="_blank" rel="noreferrer">
            <img src={whatsapp} alt="Whatsapp Contact - Icon from FlatIcon" />
          </a>
          <a href={CONSTANTS.instagram} target="_blank" rel="noreferrer">
            <img src={instagram} alt="Instagram Contact - Icon from FlatIcon" />
          </a>
          <a href={CONSTANTS.facebook} target="_blank" rel="noreferrer">
            <img src={facebook} alt="Facebook Contact - Icon from FlatIcon" />
          </a>
        </div>
      </div>
    );
  };

  const MobileItems = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(!open);
    };
    return (
      <div className={styles.mobileMenu}>
        <IconButton onClick={() => setOpen(!open)}>
          <Menu className={styles.menuIcon} />
        </IconButton>
        <Drawer open={open} anchor="top" onClose={toggleDrawer}>
          <Box
            sx={{ width: "auto" }}
            role="presentation"
            onClick={toggleDrawer}
          >
            <List>
              {headerItems.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <ListItemButton>
                    <a
                      className={styles.mobileMenuLinkItems}
                      href={item.link}
                      key={item.name}
                      rel="noreferrer"
                    >
                      <ListItemText primary={item.name} />
                    </a>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </div>
    );
  };

  return (
    <OpacityOnScroll>
      <div className={styles.headerContainer}>
        <div className={styles.leftSide}>
          <a href="#">
            <img
              src={logo_dark}
              className={styles.logo}
              alt="Logo from Canva"
            />
          </a>
        </div>
        <div className={styles.rightSide}>
          <DesktopItems />
          <SocialItems />
          <MobileItems />
        </div>
      </div>
    </OpacityOnScroll>
  );
};

export default Header;

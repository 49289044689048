import sample_1 from "./sample-1.jpg";
import sample_2 from "./sample-2.jpg";
import sample_3 from "./sample-3.jpg";
import sample_4 from "./sample-4.jpg";
import sample_5 from "./sample-5.jpg";
import sample_6 from "./sample-6.jpg";
import sample_7 from "./sample-7.jpg";

import kitchen_1 from "./kitchen-1.jpg";
import kitchen_2 from "./kitchen-2.jpg";
import kitchen_3 from "./kitchen-3.jpg";
import kitchen_4 from "./kitchen-4.jpg";
import kitchen_5 from "./kitchen-5.jpg";
import kitchen_6 from "./kitchen-6.jpg";

import living_room_1 from "./living-room-1.jpg";
import living_room_2 from "./living-room-2.jpg";

import bedroom_1 from "./bedroom-1.jpg";
import bedroom_2 from "./bedroom-2.jpg";
import bedroom_3 from "./bedroom-3.jpg";
import bedroom_4 from "./bedroom-4.jpg";

import closet_1 from "./closet-1.jpg";

import studio_1 from "./studio-1.jpg";

const IMAGES = {
  sample_1: sample_1,
  sample_2: sample_2,
  sample_3: sample_3,
  sample_4: sample_4,
  sample_5: sample_5,
  sample_6: sample_6,
  sample_7: sample_7,
  kitchen_1: kitchen_1,
  kitchen_2: kitchen_2,
  kitchen_3: kitchen_3,
  kitchen_4: kitchen_4,
  kitchen_5: kitchen_5,
  kitchen_6: kitchen_6,
  living_room_1: living_room_1,
  living_room_2: living_room_2,
  bedroom_1: bedroom_1,
  bedroom_2: bedroom_2,
  bedroom_3: bedroom_3,
  bedroom_4: bedroom_4,
  closet_1: closet_1,
  studio_1: studio_1,
};

export default IMAGES;

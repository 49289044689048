import React from "react";
import HomePage from "../view/home";
import Footer from "./components/footer";
import Header from "./components/header";
import styles from "./style.module.css";

const App = () => {
  return (
    <div className={styles.main}>
      <Header />
      <HomePage />
      <Footer />
    </div>
  );
};

export default App;
